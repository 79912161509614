.image-div {
  padding: 80px;
  border: 1px dashed rgba(145, 158, 171, 0.2);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(145, 158, 171, 0.08);
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.image-div:hover {
  background-color: rgba(145, 158, 171, 0.03);
}
